import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import { IoIosLogOut, IoIosLogIn } from "react-icons/io";
import { AiOutlineUser, AiOutlineDashboard } from "react-icons/ai";
import { FiGlobe } from "react-icons/fi";
import { ImStatsDots } from "react-icons/im";
import { MdDns, MdHttps } from "react-icons/md";

import UserContext from "../contexts/user";

export default function SidebarMenu() {
  const { setUser, isLoggedIn } = React.useContext(UserContext);
  const routes = [
    {
      name: "Dashbord",
      link: "/",
      icon: AiOutlineDashboard,
      match: "dashboard",
    },
    {
      name: "Accounts",
      link: "/accounts",
      icon: AiOutlineUser,
      match: "accounts",
    },
    {
      name: "Domains",
      link: "/domains",
      icon: FiGlobe,
      match: "domains",
    },
    {
      name: "Load Balancers",
      link: "/load-balancers",
      icon: MdDns,
      match: "load-balancers",
    },
    {
      name: "Statistics",
      link: "/statistics",
      icon: ImStatsDots,
      match: "statistics",
    },
    {
      name: "SSL Certificates",
      link: "/ssl-certs",
      icon: MdHttps,
      match: "ssl-certs",
    },
    {
      name: "Login",
      link: "/login",
      icon: IoIosLogIn,
      match: "login",
      open: true,
    },
  ];
  let { pathname } = useLocation();
  const mapper = (route, i) => (
    <li
      key={i}
      className={classNames([
        "nav-item",
        "shadow-sm",
        { active: pathname.includes(route.match) },
      ])}
    >
      <Link className="nav-link" to={route.link}>
        <route.icon /> {route.name}
      </Link>
    </li>
  );
  return (
    <ul className="nav flex-column">
      {isLoggedIn ? (
        <>
          {routes.filter((e) => !e.open).map(mapper)}
          <li className="nav-item shadow-sm mt-2 mt-sm-5">
            <button
              onClick={() => [localStorage.removeItem("token"), setUser({})]}
              className="btn btn-link nav-link"
            >
              <IoIosLogOut style={{ transform: "rotate(180deg)" }} /> Logout
            </button>
          </li>
        </>
      ) : (
        <>{routes.filter((e) => e.open).map(mapper)}</>
      )}
    </ul>
  );
}

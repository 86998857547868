import React from "react";
import Axios from "axios";
import { Redirect } from "react-router-dom";

import API from "../../utils/api";
import ErrorAlert from "../../components/ErrorAlert";

export default function NewAccount() {
  const [form, setForm] = React.useState({ email: "", key: "" });
  const [error, setError] = React.useState(null);

  const proceed = (e) => {
    e.preventDefault();
    Axios.post(API.accounts.new, form)
      .then((resp) => {
        const { account, error } = resp.data;
        if (error) return setError(error);
        if (account) setForm(account);
      })
      .catch(setError);
  };

  if (form._id) {
    return <Redirect to={"/accounts"} />;
  }

  return (
    <div className="new-account m-4">
      <div className="shadow-sm bg-white p-3">
        <h1>New Account</h1>
        <ErrorAlert error={error} setError={setError} />
        <form onSubmit={proceed} className="form">
          <fieldset className="form-group">
            <label>Email</label>
            <input
              type="text"
              className="form-control"
              value={form.email}
              onChange={(e) => setForm({ ...form, email: e.target.value })}
            />
          </fieldset>
          <fieldset className="form-group">
            <label>Key</label>
            <input
              type="text"
              className="form-control"
              value={form.key}
              onChange={(e) => setForm({ ...form, key: e.target.value })}
            />
            <small>
              <a
                href="https://prnt.sc/vu1wav"
                target="_blank"
                without
                rel="noreferrer"
              >
                How to find API Key?
              </a>
            </small>
          </fieldset>
          <div className="text-center">
            <button className="btn btn-primary px-4">Proceed</button>
          </div>
        </form>
      </div>
    </div>
  );
}

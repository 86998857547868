import React from "react";
import Axios from "axios";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Modal from "react-modal";

import UserContext from "./contexts/user";
import API from "./utils/api";
import PrivateRoute from "./components/PrivateRoute";
import SidebarMenu from "./components/SidebarMenu";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Accounts from "./pages/accounts/Accounts";
import NewAccount from "./pages/accounts/NewAccount";
import EditAccount from "./pages/accounts/EditAccount";
import Domains from "./pages/domains/Domains";
import EditDomainDNS from "./pages/domains/EditDNS";
import DomainSSL from "./pages/domains/SSL";
import Statistics from "./pages/Statistics";
import Firewall from "./pages/domains/Firewall";
import AddNewDomain from "./pages/domains/AddNew";
import SSLCerts from "./pages/SSLCerts";
import LoadBalancers from "./pages/load-balancers/LoadBalancers";
import AddNewLoadBalancer from "./pages/load-balancers/AddNew";
import EditLoadBalancer from "./pages/load-balancers/Edit";
import Mailgun from "./pages/domains/Mailgun";

Modal.setAppElement("#root");

function App() {
  const [user, setUser] = React.useState({});
  const token = localStorage.getItem("token");
  Axios.defaults.headers.common["Authorization"] = token;
  React.useEffect(() => {
    if (token && Object.keys(user).length === 0) {
      Axios.get(API.users.me).then((resp) => {
        if (resp.data.user) {
          setUser(resp.data.user);
        } else {
          localStorage.removeItem("token");
        }
      });
    }
  }, [token, user]);
  return (
    <div className="cf-panel d-flex">
      <UserContext.Provider
        value={{ user, setUser, isLoggedIn: Object.keys(user).length > 0 }}
      >
        <Router>
          <aside className="sidebar d-flex flex-column shadow">
            <div className="text-center mb-3 py-3">
              <h1 className="h3 mb-0">
                <a className="text-decoration-none" href="/">
                  Cloudflare Panel
                </a>
              </h1>
            </div>
            <div className="px-3">
              <SidebarMenu />
            </div>
          </aside>
          <main className="app-main d-flex flex-column">
            <Switch>
              <Route path="/login" component={Login} exact />
              <PrivateRoute path="/" component={Dashboard} exact />
              <PrivateRoute path="/accounts" component={Accounts} exact />
              <PrivateRoute path="/accounts/new" component={NewAccount} exact />
              <PrivateRoute
                path="/accounts/:id"
                component={EditAccount}
                exact
              />
              <PrivateRoute path="/domains" component={Domains} exact />
              <PrivateRoute
                path="/domains/add-new"
                component={AddNewDomain}
                exact
              />
              <PrivateRoute
                path="/domains/:id/dns"
                component={EditDomainDNS}
                exact
              />
              <PrivateRoute
                path="/domains/:id/firewall"
                component={Firewall}
                exact
              />
              <PrivateRoute
                path="/domains/ssl/:domain/:id"
                component={DomainSSL}
                exact
              />
              <PrivateRoute
                path="/domains/:id/mailgun"
                component={Mailgun}
                exact
              />
              <PrivateRoute
                path="/load-balancers"
                component={LoadBalancers}
                exact
              />
              <PrivateRoute
                path="/load-balancers/add-new"
                component={AddNewLoadBalancer}
                exact
              />
              <PrivateRoute
                path="/load-balancers/:id"
                component={EditLoadBalancer}
                exact
              />
              <PrivateRoute path="/statistics" component={Statistics} exact />
              <PrivateRoute path="/ssl-certs" component={SSLCerts} exact />
            </Switch>
          </main>
        </Router>
      </UserContext.Provider>
    </div>
  );
}

export default App;
